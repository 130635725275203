import Header from "../Components/Header";
import PageTitle from "../Components/PageTitle";
import Footer from "../Components/Footer";
import { useTranslation } from "react-i18next";

function Banners() {
  const { t } = useTranslation();

  const banners = [
    {
      title: t("about-usage-banner-1-title"),
      description: [
        t("about-usage-banner-1-description-1"),
        t("about-usage-banner-1-description-2"),
      ],
      hashtag: ["MEDISTEP", "MEDISTEP M pro"],
      imgSrc: "/About/Usage/medical.png",
    },
    {
      title: t("about-usage-banner-2-title"),
      description: [
        t("about-usage-banner-2-description-1"),
        t("about-usage-banner-2-description-2"),
      ],
      hashtag: ["MEDISTEP", "MEDISTEP M", "GAIT STUDIO"],
      imgSrc: "/About/Usage/welfare.png",
    },
    {
      title: t("about-usage-banner-3-title"),
      description: [
        t("about-usage-banner-3-description-1"),
        t("about-usage-banner-3-description-2"),
      ],
      hashtag: ["MEDISTEP M pro", "MEDISTEP M", "GAIT STUDIO"],
      imgSrc: "/About/Usage/rehabilitation.png",
    },
    {
      title: t("about-usage-banner-4-title"),
      description: [
        t("about-usage-banner-4-description-1"),
        t("about-usage-banner-4-description-2"),
      ],
      hashtag: ["MEDISTEP", "MEDISTEP M pro", "MEDISTEP M", "GAIT STUDIO"],
      imgSrc: "/About/Usage/research.png",
    },
  ];

  return (
    <section id="banners">
      {banners.map((item, idx) => (
        <UsageBanner key={idx} content={item} />
      ))}
    </section>
  );

  function UsageBanner({ content }) {
    return (
      <div
        className="usageBanner"
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${content.imgSrc}) no-repeat center center`,
          backgroundSize: "cover",
        }}
      >
        <p className="title">{content.title.toUpperCase()}</p>
        <div className="descriptionWrapper">
          {content.description.map((item, idx) => (
            <p key={idx}>{item}</p>
          ))}
        </div>
        <div className="hashtagWrapper">
          {content.hashtag.map((item, idx) => (
            <p key={idx}>{`# ${item}`}</p>
          ))}
        </div>
      </div>
    );
  }
}

function PoC() {
  const { t } = useTranslation();

  const poc = [
    {
      imgSrc: "/About/Usage/poc1.png",
      caption: t("about-usage-poc-1"),
    },
    {
      imgSrc: "/About/Usage/poc2.png",
      caption: t("about-usage-poc-2"),
    },
    {
      imgSrc: "/About/Usage/poc3.png",
      caption: t("about-usage-poc-3"),
    },
    {
      imgSrc: "/About/Usage/poc4.png",
      caption: t("about-usage-poc-4"),
    },
    {
      imgSrc: "/About/Usage/poc5.png",
      caption: t("about-usage-poc-5"),
    },
    {
      imgSrc: "/About/Usage/poc6.png",
      caption: t("about-usage-poc-6"),
    },
  ];

  return (
    <section id="poc">
      <article className="title">
        <p>{t("about-usage-poc-title")}</p>
        <p>{t("about-usage-poc-subTitle")}</p>
      </article>

      <div id="bar" />

      <article className="window">
        <div className="content">
          {poc.map((item, idx) => (
            <PoCCard key={idx} poc={item} />
          ))}

          <div className="tail">
            {poc.map((item, idx) => (
              <PoCCard key={idx} poc={item} />
            ))}
          </div>
        </div>
      </article>
    </section>
  );

  function PoCCard({ poc }) {
    return (
      <div className="pocCard">
        <img src={poc.imgSrc} alt={poc.caption} />
        <p>{poc.caption}</p>
      </div>
    );
  }
}

function Inquiry() {
  const { t } = useTranslation();

  return (
    <section id="inquiry">
      <div>
        <p>{t("about-inquiry-title")}</p>
        <p>{t("about-inquiry-subtitle")}</p>
      </div>
      <a href="mailto:amazing@aitstudio.co.kr" target="_blank" rel="noreferrer">
        {t("about-inquiry-inquiry")}
      </a>
    </section>
  );
}

export default function AboutUsagePage() {
  const { t } = useTranslation();

  return (
    <div className="page" id="aboutUsage">
      <Header />
      <main>
        <PageTitle text={t("about-usage-pageTitle")} />
        <div id="pageContent">
          <Banners />
          <PoC />
          <Inquiry />
        </div>
      </main>
      <Footer />
    </div>
  );
}
