import Header from "../Components/Header";
import PageTitle from "../Components/PageTitle";
import Footer from "../Components/Footer";
import { Stack } from "@mui/material";
import { KeyboardDoubleArrowRight } from "@mui/icons-material";

import { useTranslation } from "react-i18next";

function NewsCardGroup() {
  const { t } = useTranslation();

  const news = [
    {
      imgSrc: "/About/News/image-250214.jpg",
      title: t("about-news-250214"),
      link: "https://it.donga.com/106548/",
    },
    {
      imgSrc: "/About/News/image-250118.png",
      title: t("about-news-250118"),
      link: "https://n.news.naver.com/mnews/article/055/0001222646?sid=101",
    },
    {
      imgSrc: "/About/News/image-0.png",
      title: t("about-news-0"),
      link: "https://kr.aving.net/news/articleView.html?idxno=1794499",
    },
    {
      imgSrc: "/About/News/image-1.png",
      title: t("about-news-1"),
      link: "https://kr.aving.net/news/articleView.html?idxno=1794423",
    },
    {
      imgSrc: "/About/News/image-2.png",
      title: t("about-news-2"),
      link: "https://digitalchosun.dizzo.com/site/data/html_dir/2024/10/03/2024100380045.html",
    },
    {
      imgSrc: "/About/News/image-3.png",
      title: t("about-news-3"),
      link: "https://www.etnews.com/20240906000049",
    },
    {
      imgSrc: "/About/News/image-4.png",
      title: t("about-news-4"),
      link: "https://www.etnews.com/20240906000033",
    },
    {
      imgSrc: "/About/News/image-5.png",
      title: t("about-news-5"),
      link: "https://www.fntoday.co.kr/news/articleView.html?idxno=333245",
    },
    {
      imgSrc: "/About/News/image-6.png",
      title: t("about-news-6"),
      link: "https://www.ekn.kr/web/view.php?key=20240628000920301",
    },
    {
      imgSrc: "/About/News/image-7.png",
      title: t("about-news-7"),
      link: "https://www.bokjitimes.com/news/articleView.html?idxno=37845",
    },
    {
      imgSrc: "/About/News/image-8.png",
      title: t("about-news-8"),
      link: "https://www.seongdongnews.com/news/articleView.html?idxno=30779",
    },
    {
      imgSrc: "/About/News/image-9.png",
      title: t("about-news-9"),
      link: "https://www.skyedaily.com/news/news_view.html?ID=233726",
    },
    {
      imgSrc: "/About/News/image-10.png",
      title: t("about-news-10"),
      link: "https://www.newscj.com/news/articleView.html?idxno=3113969",
    },
    {
      imgSrc: "/About/News/image-11.png",
      title: t("about-news-11"),
      link: "https://www.yna.co.kr/view/AKR20240229001200017",
    },
    {
      imgSrc: "/About/News/image-12.png",
      title: t("about-news-12"),
      link: "https://www.newscj.com/news/articleView.html?idxno=3113706",
    },
    {
      imgSrc: "/About/News/image-13.png",
      title: t("about-news-13"),
      link: "https://www.smedaily.co.kr/news/articleView.html?idxno=282856",
    },
    {
      imgSrc: "/About/News/image-14.png",
      title: t("about-news-14"),
      link: "https://www.etnews.com/20231206000020",
    },
    {
      imgSrc: "/About/News/image-15.png",
      title: t("about-news-15"),
      link: "https://www.ccnnews.co.kr/news/articleView.html?idxno=316296",
    },
    {
      imgSrc: "/About/News/image-16.png",
      title: t("about-news-16"),
      link: "https://www.mk.co.kr/news/it/10880891",
    },
  ];

  return (
    <section id="newsCardGroup">
      {news.map((item, idx) => (
        <NewsCard key={idx} news={item} />
      ))}
    </section>
  );

  function NewsCard({ news }) {
    return (
      <div className="newsCard">
        <a href={news.link} target="_blank" rel="noreferrer">
          <div className="cardContent">
            <figure>
              <img src={news.imgSrc} alt={news.title} />
            </figure>

            <div>
              <p>{news.title}</p>
              <Stack direction="row" alignItems="center" className="viewMore">
                <KeyboardDoubleArrowRight />
                <p>{t("about-news-viewMore")}</p>
              </Stack>
            </div>
          </div>
        </a>
      </div>
    );
  }
}

export default function AboutNewsPage() {
  const { t } = useTranslation();

  return (
    <div className="page" id="aboutNews">
      <Header />
      <main>
        <PageTitle text={t("about-news-pageTitle")} />
        <div id="pageContent">
          <NewsCardGroup />
        </div>
      </main>
      <Footer />
    </div>
  );
}
