import Header from "../Components/Header";
import Footer from "../Components/Footer";
import PageTitle from "../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { East } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useCallback } from "react";

function Background() {
  const { t } = useTranslation();

  const references = [
    {
      title: t("about-company-background-ref-1-title"),
      type: "paper",
      displayType: t("about-company-background-ref-1-displayType"),
      subTitle: t("about-company-background-ref-1-subTitle"),
      link: "https://pubmed.ncbi.nlm.nih.gov/27302701/",
    },
    {
      title: t("about-company-background-ref-2-title"),
      type: "news",
      displayType: t("about-company-background-ref-2-displayType"),
      subTitle: t("about-company-background-ref-2-subTitle"),
      link: "https://m.health.chosun.com/svc/news_view.html?contid=2021042001947",
    },
    {
      title: t("about-company-background-ref-3-title"),
      type: "news",
      displayType: t("about-company-background-ref-3-displayType"),
      subTitle: t("about-company-background-ref-3-subTitle"),
      link: "https://m.dongascience.com/news.php?idx=53798",
    },
    {
      title: t("about-company-background-ref-4-title"),
      type: "paper",
      displayType: t("about-company-background-ref-4-displayType"),
      subTitle: t("about-company-background-ref-4-subTitle"),
      link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3806920/",
    },
    {
      title: t("about-company-background-ref-5-title"),
      type: "paper",
      displayType: t("about-company-background-ref-5-displayType"),
      subTitle: t("about-company-background-ref-5-subTitle"),
      link: "https://pubmed.ncbi.nlm.nih.gov/30498834/",
    },
    {
      title: t("about-company-background-ref-6-title"),
      type: "news",
      displayType: t("about-company-background-ref-6-displayType"),
      subTitle: t("about-company-background-ref-6-subTitle"),
      link: "https://mobile.hidoc.co.kr/healthstory/news/C0000783225",
    },
  ];

  return (
    <section id="background">
      <aside>{t("about-company-background-aside")}</aside>
      <article>
        <p>
          {t("about-company-background-paragraph-1-1")}
          <br />
          {t("about-company-background-paragraph-1-2")}
        </p>

        <figure>
          {references.map((ref, idx) => (
            <RefCard key={idx} reference={ref} />
          ))}
        </figure>

        <p>
          {t("about-company-background-paragraph-2-1")}
          <br />
          {t("about-company-background-paragraph-2-2")}
          <br />
          {t("about-company-background-paragraph-2-3")}
          <br />
          {t("about-company-background-paragraph-2-4")}
        </p>

        <p>
          {t("about-company-background-paragraph-3-1-regular-1")}
          <span>{t("about-company-background-paragraph-3-1-bold")}</span>
          {t("about-company-background-paragraph-3-1-regular-2")}
          <br />
          {t("about-company-background-paragraph-3-2")}
        </p>
      </article>
    </section>
  );

  function RefCard({ reference }) {
    return (
      <div className="refCard">
        <a href={reference.link} target="_blank" rel="noreferrer">
          <div className="cardContent">
            <p className="title">{reference.title}</p>
            <p className="subTitle">
              <span
                className={reference.type}
              >{`${reference.displayType}`}</span>{" "}
              {`- ${reference.subTitle}`}
            </p>
            <div>
              <East />
            </div>
          </div>
        </a>
      </div>
    );
  }
}

function MissionVision() {
  const { t } = useTranslation();

  return (
    <section id="missionVision">
      <aside>{t("about-company-missionvission-aside")}</aside>
      <article>
        <div className="visionCard">
          <p>
            {t("about-company-missionvission-1-1")}
            <br />
            {t("about-company-missionvission-1-2")}
          </p>
        </div>
        <div className="visionCard">
          <p>
            {t("about-company-missionvission-2-1")}
            <br />
            {t("about-company-missionvission-2-2")}
            <br />
            {t("about-company-missionvission-2-3")}
          </p>
        </div>
      </article>
    </section>
  );
}

function History() {
  const { t } = useTranslation();

  const history = [
    {
      year: 2024,
      history: [
        {
          month: 12,
          event: [
            t("about-company-history-2024-12-1"),
            t("about-company-history-2024-12-2"),
          ],
        },
        {
          month: 10,
          event: [
            t("about-company-history-2024-10-1"),
            t("about-company-history-2024-10-2"),
          ],
        },
        {
          month: 7,
          event: [t("about-company-history-2024-7-1")],
        },
        {
          month: 6,
          event: [t("about-company-history-2024-6-1")],
        },
        {
          month: 1,
          event: [t("about-company-history-2024-1-1")],
        },
      ],
    },
    {
      year: 2023,
      history: [
        {
          event: [t("about-company-history-2023-1")],
          li: [t("about-company-history-2023-1-li")],
        },
      ],
    },
    {
      year: 2022,
      history: [
        {
          month: 7,
          event: [t("about-company-history-2022-1")],
        },
      ],
    },
  ];

  return (
    <section id="history">
      <aside>{t("about-company-history-aside")}</aside>
      <article>
        {history.map((item, idx) => (
          <HistoryOfYear key={idx} year={item.year} history={item.history} />
        ))}
      </article>
    </section>
  );

  function HistoryOfYear({ year, history }) {
    return (
      <div className="historyOfYear">
        <p className="year">{year}</p>
        <div>
          {history.map((item, idx) => (
            <HistoryOfMonth
              key={idx}
              month={item.month}
              event={item.event}
              li={item.li}
            />
          ))}
        </div>
      </div>
    );
  }

  function HistoryOfMonth({ month, event, li }) {
    return (
      <div className="historyOfMonth">
        {month !== undefined && (
          <p className="month">{month.toString().padStart(2, "0")}</p>
        )}

        <div>
          {event.map((item, idx) => (
            <p key={idx}>{item}</p>
          ))}

          {li !== undefined && (
            <ul>
              {li.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

function Members() {
  const { t } = useTranslation();

  const members = [
    {
      name: t("about-company-members-singiPark-name"),
      position: t("about-company-members-singiPark-position"),
      li: [
        t("about-company-members-singiPark-li-1"),
        t("about-company-members-singiPark-li-2"),
        t("about-company-members-singiPark-li-3"),
      ],
    },
    {
      name: t("about-company-members-jaerinNoh-name"),
      position: t("about-company-members-jaerinNoh-position"),
      li: [
        t("about-company-members-jaerinNoh-li-1"),
        t("about-company-members-jaerinNoh-li-2"),
        t("about-company-members-jaerinNoh-li-3"),
        t("about-company-members-jaerinNoh-li-4"),
        t("about-company-members-jaerinNoh-li-5"),
      ],
    },
    {
      name: t("about-company-members-heeminJu-name"),
      position: t("about-company-members-heeminJu-position"),
    },
    {
      name: t("about-company-members-chanwooJeong-name"),
      position: t("about-company-members-chanwooJeong-position"),
    },
    {
      name: t("about-company-members-nohyunKim-name"),
      position: t("about-company-members-nohyunKim-position"),
    },
    {
      name: t("about-company-members-junyoungAn-name"),
      position: t("about-company-members-junyoungAn-position"),
    },
  ];

  return (
    <section id="members">
      <aside>{t("about-company-members-aside")}</aside>
      <article>
        {members.map((member, idx) => (
          <MemberCard key={idx} info={member} />
        ))}
      </article>
    </section>
  );

  function MemberCard({ info }) {
    return (
      <div className="memberCard">
        <p className="name">{info.name}</p>
        <div className="description">
          <p>AIT STUDIO</p>
          <p>{info.position}</p>
          {info.li !== undefined &&
            info.li.map((item, idx) => (
              <p key={idx} className="list">
                {item}
              </p>
            ))}
        </div>
      </div>
    );
  }
}

function Resource() {
  const { t } = useTranslation();

  const files = [
    {
      name: t("about-company-resource-file-1"),
      link: "AIT_Studio_brochure_Gait_studio_en.pdf",
    },
    {
      name: t("about-company-resource-file-2"),
      link: "AIT_Studio_Accuracy Evaluation of Walking Speed and Step Length Measurements_en.pdf",
    },
  ];

  const downloadFile = useCallback((filename) => {
    // using Java Script method to get PDF file
    fetch(filename).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);

        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = filename;
        alink.click();
      });
    });
  }, []);

  return (
    <section id="resource">
      <aside>{t("about-company-resource-aside")}</aside>
      <article>
        {files.map((file, idx) => (
          <FileDownloader key={idx} file={file} />
        ))}
      </article>
    </section>
  );

  function FileDownloader({ file }) {
    return (
      <div className="fileDownloader">
        <p>{file.name}</p>
        <Button
          onClick={() => {
            downloadFile(file.link);
          }}
        >
          {t("about-company-resource-file-download")}
        </Button>
      </div>
    );
  }
}

export default function AboutCompanyPage() {
  const { t } = useTranslation();

  return (
    <div className="page" id="aboutCompany">
      <Header />
      <main>
        <PageTitle text={t("about-company-pageTitle")} />
        <div id="pageContent">
          <Background />
          <MissionVision />
          <History />
          <Members />
          <Resource />
        </div>
      </main>
      <Footer />
    </div>
  );
}
